body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
    font-family: 'Amasis';
    src: url(/static/media/amasismtstd-webfont.e06ef0a0.eot);
    src: url(/static/media/amasismtstd-webfont.e06ef0a0.eot?#iefix) format('embedded-opentype'),
        url(/static/media/amasismtstd-webfont.d277634f.woff2) format('woff2'),
        url(/static/media/amasismtstd-webfont.9759fb82.woff) format('woff'),
        url(/static/media/amasismtstd-webfont.73bc34ff.ttf) format('truetype'),
        url(/static/media/amasismtstd-webfont.773db39f.svg#amasis_mt_stdregular) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Amasis';
    src: url(/static/media/amasismtstd-medium-webfont.f4647e34.eot);
    src: url(/static/media/amasismtstd-medium-webfont.f4647e34.eot?#iefix) format('embedded-opentype'),
        url(/static/media/amasismtstd-medium-webfont.a7db1e71.woff2) format('woff2'),
        url(/static/media/amasismtstd-medium-webfont.422b187c.woff) format('woff'),
        url(/static/media/amasismtstd-medium-webfont.9e6e6125.ttf) format('truetype'),
        url(/static/media/amasismtstd-medium-webfont.32fbb7ca.svg#amasis_mt_stdmedium) format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Amasis';
    src: url(/static/media/amasismtstd-bold-webfont.88414436.eot);
    src: url(/static/media/amasismtstd-bold-webfont.88414436.eot?#iefix) format('embedded-opentype'),
        url(/static/media/amasismtstd-bold-webfont.e20038dc.woff2) format('woff2'),
        url(/static/media/amasismtstd-bold-webfont.765a6bad.woff) format('woff'),
        url(/static/media/amasismtstd-bold-webfont.eefbff3b.ttf) format('truetype'),
        url(/static/media/amasismtstd-bold-webfont.28711cd0.svg#amasis_mt_stdbold) format('svg');
    font-weight: 700;
    font-style: normal;
}

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    overflow-y: scroll;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Amasis';
    font-weight: 400;
    color: #404040;
}

a {
    text-decoration: none;
    color: #404040;
}

h1,
h2,
h3,
p,
ul,
ol {
    margin: 0;
}

h2 {
    margin-left: -2px;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    color: #2a72ba;
}

ul,
ol {
    padding: 0;
    list-style: none;
}

/* */

.table {
    display: table;
    position: relative;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 0;
}

.table>.col {
    position: relative;
    display: table-cell;
    width: auto;
    padding: 0px;
    vertical-align: top;
}

.center {
    position: relative;
    margin: 0 auto;
}

.clear:after {
    content: '';
    display: block;
    clear: both;
}

a.area {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.blau {
    color: #2a72ba;
}

/* */

.center.main {
    max-width: 1224px;
    padding: 0 100px;
}

.center.main>.inner {
    position: relative;
}

/* Scrollbar */

#scrollbar {
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 3px;
    background: rgba(204, 204, 204, 0.75);
}

#scrollbar>span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 3px;
    background: #2a72ba;
    -webkit-transition: -webkit-transform 0.2s ease-out;
    transition: -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out;
    transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
    -webkit-transition: transform 0.2s ease-out;
    -moz-transition: transform 0.2s ease-out;
    -o-transition: transform 0.2s ease-out;
    -webkit-transform-origin: left top;
            transform-origin: left top;
}

/* Header */

#header {
    position: absolute;
    z-index: 99;
    left: 0%;
    top: 0;
    width: 100%;
    background: #fff;
}

#header .inner {
    padding: 19px 0 16px 0;
}

#logo {
    position: relative;
    display: block;
    width: 92px;
    height: 92px;
}

#logo a {
    position: relative;
    display: block;
    width: 92px;
    height: 92px;
    background: url(/static/media/logo.ec9a013a.svg);
    background-size: contain;
}

#logo span {
    position: absolute;
    left: 100%;
    top: 44px;
    width: 250%;
    margin-left: 13px;
    font-size: 21px;
    line-height: 0px;
    color: #808080;
}

#header .project.buttons {
    position: fixed;
    display: block;
    height: 0;
    top: 146px;
}

.project.buttons span {
    display: block;
    position: relative;
    width: 19px;
    height: 36px;
    cursor: pointer;
}

.project.buttons span.previous {
    float: left;
    margin-left: -49px;
}

.project.buttons span.next {
    float: right;
    margin-right: -49px;
}

.project.buttons span.next svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.project.buttons>span>span {
    position: absolute;
    left: -23px;
    top: 44px;
    width: 70px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #808080;
    pointer-events: none;
    opacity: 0;
}

.project.buttons span:hover .stroke {
    stroke: #2a72ba
}

.project.buttons span:hover>span {
    opacity: 1;
}

#header .menu {
    position: fixed;
    margin-left: 31px;
    width: 29px;
    height: 21px;
    top: 52px;
    cursor: pointer;
    display: none;
}

#header .menu span {
    display: block;
    position: absolute;
    left: 2px;
    top: 2px;
    width: 25px;
    height: 3px;
    background: #808080;
}

#header .menu span+span {
    top: 9px;
}

#header .menu span+span+span {
    top: 16px;
}

#header.open .menu span:nth-child(1) {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 9px;
}

#header.open .menu span:nth-child(2) {
    opacity: 0;
}

#header.open .menu span:nth-child(3) {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 9px;
}

#header .menu:hover span {
    background: #2a72ba;
}

#header>.bottom,
#header>.bottom>span {
    position: absolute;
    z-index: -1;
    left: 0%;
    bottom: 0;
    width: 100%;
    height: 200px;
}

#header>.bottom {
    display: none;
}

#header>.bottom>span {
    width: 120%;
    left: -10%;
}

#header.scrolled .menu {
    display: block;
}

#header:not(.mobile).scrolled {
    position: fixed;
    top: -150px;
    -webkit-transition: margin 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transition: margin 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

#header:not(.mobile).scrolled>.bottom {
    display: block;
}

#header:not(.mobile).scrolled>.bottom>span {
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.25);
}

#header:not(.mobile).scrolled.open {
    margin-top: 150px;
}

#navigation {
    position: absolute;
    right: 0px;
    top: 52px;
    width: 540px;
}

#navigation a {
    color: #808080;
}

#navigation a:hover,
#navigation a.selected {
    color: #2a72ba;
}

#navigation ul li {
    float: left;
}

#navigation .main {
    float: right;
}

#navigation .main a {
    padding: 0 2px;
    margin: 0 10px;
    font-size: 21px;
    line-height: 21px;
}

#navigation .sub {
    position: absolute;
    top: 22px;
    height: 31px;
    overflow: hidden;
    padding-top: 8px;
    right: 0;
}

#navigation .sub a {
    padding: 0 1px;
    margin: 0 6.5px;
    font-size: 16px;
}

#navigation ul li:last-child a {
    padding-right: 0;
    margin-right: 0;
}

/* Page */

#react {
    padding-top: 127px;
}

.page {
    position: relative;
}

.page .loading {
    position: absolute;
    min-width: 62px;
    left: 50%;
    top: 40%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: #808080;
}

.page .loading::after {
    content: "";
    -webkit-animation: loadingAnimation 1.6s infinite;
            animation: loadingAnimation 1.6s infinite;
}

@-webkit-keyframes loadingAnimation {
    0% {
        content: "";
    }

    25% {
        content: ".";
    }

    50% {
        content: "..";
    }

    75% {
        content: "...";
    }
}

@keyframes loadingAnimation {
    0% {
        content: "";
    }

    25% {
        content: ".";
    }

    50% {
        content: "..";
    }

    75% {
        content: "...";
    }
}

/* Content */

.rich p+p {
    margin-top: 13px;
}

.rich h3 {
    font-weight: 500;
    color: #2a72ba;
}

.rich ul.stichpunkte li {
    position: relative;
    padding-left: 11px;
}

.rich ul.stichpunkte li:before {
    position: absolute;
    content: "- ";
    left: 0;
    top: 0;
}

.rich p+p,
.rich p+h3 {
    margin-top: 13px;
}

.rich b,
.rich strong {
    font-weight: 500;
}

.rich a:not(.tel) {
    padding-bottom: 1px;
    border-bottom: 1px solid #2a72ba;
    margin-bottom: -1px;
}

.rich a:not(.tel):hover {
    color: #2a72ba;
}

.rich table.adresse {
    margin-top: 13px;
}

.rich table.adresse_extra {
    margin-top: 39px;
}

.rich ul.stellenangebote {
    margin: 19px 0px 20px 0px;
    border-top: 1px solid #BCBCBC;
}

.rich ul.stellenangebote li {
    padding: 9px 0px 10px 0px;
    border-bottom: 1px solid #BCBCBC;
}

.rich div.credits_klein,
.rich div.credits_klein_2,
.rich div.datenschutz_klein {
    font-size: 15px;
    color: #666;
}

.rich div.credits_klein {
    margin-top: 78px;
}

.rich div.credits_klein_2 {
    margin-top: 52px;
}

.rich a.mail_impressum {
    border: none !important;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    text-decoration: none !important;
}

.rich a.link_design {
    color: #666;
    border: none !important;
    padding: 0px 0px 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    text-decoration: none !important;
}

.rich a.link_design:hover {
    color: #2A72BA;
}

/* Overview */

.content.overview {
    position: relative;
    margin-bottom: 108px;
}

.content.overview>div {
    position: absolute;
    background: #DDD;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    will-change: transform;
    -webkit-transition: transform 500ms ease-out;
    -webkit-transition: -webkit-transform 500ms ease-out;
    transition: -webkit-transform 500ms ease-out;
    transition: transform 500ms ease-out;
    transition: transform 500ms ease-out, -webkit-transform 500ms ease-out;
}

.content.overview:not(.resizing)>div {
    -webkit-transition: transform 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
    -webkit-transition: -webkit-transform 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
    transition: -webkit-transform 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
    transition: transform 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
    transition: transform 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955), -webkit-transform 500ms cubic-bezier(0.455, 0.030, 0.515, 0.955);
}

.content.overview>div>.hover {
    position: absolute;
    overflow: hidden;
    left: -1px;
    top: 1px;
    width: 100%;
    height: 100%;
}

.content.overview>div>.hover>.text {
    position: absolute;
    left: 1px;
    top: 100%;
    padding-right: 10px;
    margin-left: 0px;
    -webkit-transition: -webkit-transform 0.12s ease-out;
    transition: -webkit-transform 0.12s ease-out;
    transition: transform 0.12s ease-out;
    transition: transform 0.12s ease-out, -webkit-transform 0.12s ease-out;
    -webkit-transition: transform 0.12s ease-out;
    -moz-transition: transform 0.12s ease-out;
    -o-transition: transform 0.12s ease-out;
    will-change: transform;
}

.content.overview>div>.hover>.text:before {
    position: absolute;
    left: -3px;
    width: 3px;
    top: 0;
    height: 200%;
    background: #fff;
    content: "";
}

.touch .content.overview>div>.hover>.text {
    top: auto;
    bottom: 5.5px;
}

.content.overview>div>.hover>.text>span:not(.clear) {
    float: left;
    display: block;
    height: 31px;
    padding-right: 8px;
    margin: 0 -3px -10px 0;
    background: #fff;
    font-size: 20px;
    line-height: 29px;
    color: #2a72ba;
}

.content.overview>div>.hover>.text>span:not(.clear)>span {
    position: relative;
    z-index: 1;
}

/* Project */

.content.project {
    padding-top: 19px;
}

.content.project h2 {
    margin-bottom: 14px;
}

.content.project h3 {
    margin-bottom: 30px;
    font-size: 22px;
    line-height: 22px;
    font-weight: 400;
    color: #808080;
}

.content.project .sizeFull .col:nth-child(1) {
    width: 59.39%;
}

.content.project .sizeFull .col:nth-child(2) {
    padding-left: 16px;
}

.content.project .slider {
    position: relative;
    max-width: 632px;

}

.content.project .slider .media {
    position: relative;
    overflow: hidden;
}

.content.project .slider .media>div {
    position: relative;
    float: left;
    width: 100%;
    padding-bottom: 66.45%;
    background: #DDD;
}

.content.project .slider .media>div>ul {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    will-change: transform;
}

.content.project .slider:not(.touching) .media>div>ul {
    -webkit-transition: -webkit-transform 0.4s ease-out;
    transition: -webkit-transform 0.4s ease-out;
    transition: transform 0.4s ease-out;
    transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
    -webkit-transition: transform 0.4s ease-out;
    -moz-transition: transform 0.4s ease-out;
    -o-transition: transform 0.4s ease-out;
}


.content.project .slider .media ul li {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.content.project .slider .button {
    position: absolute;
    z-index: 20;
    cursor: pointer;
}

.content.project .slider .button svg {
    width: 24px;
    height: 24px;
}

.content.project .slider .button.next svg {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.content.project .slider:not(.touch) .button:hover .stroke {
    stroke: #2a72ba;
}

.content.project .slider.touch .button {
    width: 24px;
    height: 24px;
    top: 50%;
    margin-top: -12px;
}

.content.project .slider.touch .button.previous {
    left: 10px;
}

.content.project .slider.touch .button.next {
    right: 10px;
}

.content.project .slider:not(.touch) .button {
    width: 50%;
    height: 100%;
}

.content.project .slider:not(.touch) .button svg {
    position: absolute;
    top: 50%;
    margin-top: -12px;
}

.content.project .slider:not(.touch) .button.previous {
    left: 0;
}

.content.project .slider:not(.touch) .button.previous svg {
    left: 10px;
}

.content.project .slider:not(.touch) .button.next {
    right: 0;
}

.content.project .slider:not(.touch) .button.next svg {
    right: 10px;
}

.content.project .slider .bottom {
    position: relative;
    min-height: 50px;
    padding-top: 8px;
    padding-bottom: 24px;
}

.content.project .slider.single .bottom {
    min-height: 30px;
    padding-bottom: 0;
}

.content.project .slider .text {
    position: relative;
    padding-right: 45px;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    color: #2a72ba;
}

.content.project .slider .copyright {
    position: absolute;
    left: -25px;
    top: 300px;
    width: 300px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: left top;
            transform-origin: left top;
    text-align: right;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    color: #BBB;
    font-size: 12px;
}

.content.project .slider .count {
    position: absolute;
    right: 0;
    top: 8px;
    font-size: 15px;
    line-height: 18px;
    color: #808080;
}

.content.project .data {
    font-size: 15px;
    line-height: 18px;
    color: #2a72ba;
}

.content.project .data table {
    border-top: 1px solid #BCBCBC;
    border-spacing: 0px;
}

.content.project .data table tr td {
    border-bottom: 1px solid #BCBCBC;
    padding: 7px 0;
    vertical-align: top;
}

.content.project .data table tr td:nth-child(1) {
    width: 1%;
    white-space: nowrap;
    padding-left: 16px;
    padding-right: 8px;
}

.content.project .data table tr td:nth-child(2) {
    width: auto;
}

.content.project .text {
    margin-bottom: 116px;
}

.content.project .sizeMedium .data {
    float: right;
    width: 520px;
    margin: 8px 0 15px 15px;
}

.content.project .sizeSmall .slider {
    max-width: 100%;
}

.content.project .sizeSmall .data {
    margin-bottom: 30px;
}

.content.project .sizeSmall .data table tr td:nth-child(1) {
    padding-left: 0px;
    padding-right: 16px;
}

.content.project .project.buttons {
    display: none;
    margin: -64px 0 60px 0;
}

.content.project .project.buttons:after {
    content: '';
    display: block;
    clear: both;
}

.touch .content.project .project.buttons>span {
    width: 86px;
}

.content.project .project.buttons span svg {
    width: 19px;
    height: 36px;
}

.content.project .project.buttons>span>span {
    top: 0;
    font-size: 15px;
    line-height: 18px;
}

.touch .content.project .project.buttons>span>span {
    opacity: 1;
}

.content.project .project.buttons>span.previous {
    margin-left: 0;
}

.content.project .project.buttons>span.previous>span {
    left: 30px;
    text-align: left;
}

.content.project .project.buttons>span.next {
    margin-right: 0;
}

.content.project .project.buttons>span.next svg {
    float: right;
}

.content.project .project.buttons>span.next>span {
    left: inherit;
    right: 30px;
    text-align: right;
}

/* Pages */

.content.standard .image.standard {
    margin-bottom: 26px;
    background: #DDD;
}

.content.standard .image.standard div {
    background-size: cover;
    background-position: center;
    padding-bottom: 28.947%;
}

.content.standard>div {
    padding-top: 19px;
    padding-bottom: 50px;
}

.content.standard>div+div {
    padding-top: 36px;
    border-top: 1px solid #2A72BA;
}

.content.standard>div:last-child {
    padding-bottom: 117px;
}

.content.standard h2 {
    margin-bottom: 26px;
}

.content.standard .cols .space {
    margin-left: -8px;
    margin-right: -8px;
    width: auto;
}

.content.standard .cols .table .col {
    padding-left: 8px;
    padding-right: 8px;
}


.content.standard .cols .table .col .rich {
    padding-right: 16px;

}

.content.standard .cols.size4.cols2 .col {
    float: left;
    width: 40%;
}

.content.standard .cols.size5.cols2 .col {
    width: 50%;
}

.content.standard .cols.size4.cols1 .col {
    float: left;
    width: 80%;
}



/* About */


.content.about .content2 .table .col:nth-child(1) {
    width: 20%;
}

.content.about .content2 .table .col:nth-child(2),
.content.about .content2 .table .col:nth-child(3) {
    width: 40%;
}

.content.about .content2 .cols.image {
    position: relative;
    padding-bottom: 28.947%;
    margin-bottom: 26px;
}

.content.about .content2 .cols.image .space {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: 100%;
}

.content.about .content2 .cols.image .space .table {
    height: 100%;
}

.content.about .content2 .cols.image .image div {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #DDD;
    border-left: 8px solid #fff;
    border-right: 8px solid #fff;
    background-size: cover;
    background-position: center center;
}

.content.about .content3 .video {
    position: relative;
    margin-top: 36px;
    padding-bottom: 56.25%;
}

.content.about .content3 .video iframe {
    position: absolute;
    left: 0;
    top: 0;
}

/* Contact */

.content.contact h3 {
    margin-bottom: 13px;
}

/* Text */

.content.text h2 {
    font-size: 30px;
    line-height: 30px;
    margin-left: -1px;
}

/* Footer */

#footer {
    font-size: 15px;
    line-height: 22px;
    border-top: 1px solid #BCBCBC;
    color: #808080;
}

#footer a {
    color: #808080;
}

#footer .cols {
    margin-top: 15px;
    padding-bottom: 86px;
    width: 100%;
}

#footer .space {
    margin-left: -32px;
    width: auto;
}

#footer .table .col {
    padding-left: 32px;
}

#footer p+p,
#footer ul li+li {
    margin-top: 8px;
}

#footer ul {
    float: right;
}

#footer a:not(.tel):hover,
#footer a.selected {
    color: #2a72ba;
}

#footer {
    position: relative;
}

#footer>span {
    position: absolute;
    z-index: 100;
    display: block;
    left: 0;
    width: 10px;
    background: red;
    display: none;
}

#footer>span.top {
    height: 124px;
    top: -125px;
}

#footer>span.bottom {
    height: 92px;
    bottom: 0px;
}

/* */

.fade-appear,
.fade-enter {
    z-index: 2;
    opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    opacity: 1;
}

.fade-exit {
    z-index: 1;
    -webkit-transition: opacity .1s linear;
    transition: opacity .1s linear;
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
}

/* */

.fade2-appear,
.fade2-enter {
    opacity: 0;
}

.fade2-appear-active,
.fade2-enter-active {
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    opacity: 1;
}

.fade2-exit {
    -webkit-transition: opacity .1s linear;
    transition: opacity .1s linear;
    opacity: 1;
}

.fade2-exit-active {
    opacity: 0;
}


@media screen and (max-width: 1130px) {

    .content.about .content2 .table .col:nth-child(1),
    .content.about .content2 .table .col:nth-child(2),
    .content.about .content2 .table .col:nth-child(3) {
        width: 33.333%;
    }

}

@media screen and (max-width: 1080px) {

    .content.standard .cols.size4.cols2 .col {
        width: 50%;
    }

    .content.standard .cols.size4.cols1 .col {
        width: 100%;
    }

    .content.standard .cols.size4.cols1 .col .rich {
        padding-right: 0;
    }
}

#header.mobile #navigation {
    position: fixed;
    z-index: 99;
    right: inherit;
    left: -315px;
    top: -2px;
    width: 291px;
    height: 100%;
    padding-top: 2px;
    padding-bottom: 2px;
    background: #fff;
    box-shadow: 0px 3px 25px 0px rgba(0, 0, 0, 0.25);
    -webkit-transition: transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    -webkit-transition: -webkit-transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transition: -webkit-transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transition: transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    transition: transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940), -webkit-transform 300ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    box-sizing: content-box;
    overflow-y: auto;

}

#header.mobile.open #navigation {
    -webkit-transform: translateX(315px);
            transform: translateX(315px);
}

#header.mobile #navigation>a {
    display: block;
    margin: 16px 10px 10px 32px;
    font-size: 17px;
    line-height: 27px;
    font-weight: 400;
    color: #808080;
    cursor: pointer;
}

#header.mobile #navigation>a span {
    color: #2a72ba;
}

#header.mobile #navigation ul.main {
    margin-left: 32px;
}

#header.mobile #navigation ul a {
    padding: 0px;
    font-size: 17px;
    line-height: 27px;
    margin: 0;
}

#header.mobile #navigation ul ul {
    margin-left: 16px;
}

#header.mobile #navigation ul.main>li {
    margin-bottom: 8px;
}

#header.mobile #navigation .main {
    float: none;
}

#header.mobile #navigation li {
    float: none;
}

#header.mobile #navigation li.meta {
    display: none;
}

#header.mobile .menu {
    top: 17px;
    display: block;
}

#header.mobile>.bottom {
    display: none;
}


@media screen and (max-width: 981px) {
    .center.main {
        padding: 0 80px;
    }
}


@media screen and (max-width: 768px) {

    .content.about .content2 .cols:not(.image) .table .col:nth-child(1),
    .content.about .content2 .cols:not(.image) .table .col:nth-child(2),
    .content.about .content2 .cols:not(.image) .table .col:nth-child(3),
    .content.standard .cols.size4.cols2 .col,
    .content.standard .cols.size5.cols2 .col,
    #footer .table .col {
        display: block;
        width: 100%;
    }

    .content.standard .col+.col,
    .content.about .content2 .table .col:nth-child(2),
    .content.about .content2 .table .col:nth-child(3) {
        margin-top: 13px;
    }

    .content.standard .cols .table .col .rich {
        padding-right: 0;
    }

    .content.contact .col+.col {
        margin-top: 52px;
    }

    .rich table.adresse_extra {
        margin-top: 13px;
    }

    #footer .table .col+.col {
        display: none;
    }

    #header.mobile #navigation li.meta {
        display: block;
    }
}

@media screen and (max-width: 600px) {

    #react {
        padding-top: 97px;
    }

    .center.main {
        padding: 0 16px;
    }

    h2 {
        font-size: 30px;
        line-height: 30px;
        margin-left: -1px;
    }

    #logo,
    #logo a {
        width: 74px;
        height: 74px;
    }

    #logo span {
        margin-left: 12px;
        top: 36px;
        font-size: 17px;
    }

    #header.mobile .menu {
        margin-left: -27px;
    }

    #header.mobile #navigation>a,
    #header.mobile #navigation ul.main {
        margin-left: 16px;
    }

    #header.mobile #navigation>a,
    #header.mobile #navigation ul a {
        font-size: 20px;
        line-height: 31px;
    }

    .content.project .sizeSmall .slider .media {
        margin-left: -16px;
        margin-right: -16px;
    }

    .content.project .sizeSmall .slider.touch .button {
        display: none;
    }

    .content.project .sizeSmall .slider .copyright {
        display: none;
    }

    .content.project .slider .text {
        color: grey;
    }

    .content.project .project.buttons {
        display: block;
    }

    .content.standard .image.standard div {
        padding-bottom: 34.728%;
    }

    .content.overview {
        margin-bottom: 75px;
    }

    .content.standard>div:last-child {
        padding-bottom: 85px;
    }

    #footer .cols {
        padding-bottom: 55px;
    }

    #footer>span.top {
        height: 92px;
        top: -92px;
    }

    #footer>span.bottom {
        height: 60px;
        bottom: 0px;
    }

}

@media screen and (max-width: 440px) {

    .content.overview>div>.hover>.text>span:not(.clear) {
        height: 23px;
        padding-right: 6px;
        margin: 0 -2px -7px 0;
        font-size: 15px;
        line-height: 22px;
    }

    .content.overview>div>.hover {
        margin-top: 1px;
    }

    .touch .content.overview>div>.hover {
        bottom: 5px;
    }

}
